import React from "react";
import { useState } from "react";
import { FaHamburger } from "react-icons/fa";

import { Link, useNavigate } from "react-router-dom";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleClick = (path) => {
    // Navigate to the specified path
    navigate(path);

    if (path === "/about") {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
    }

    // Scroll to the top
    window.scrollTo(0, 0);

    // Close the menu if it's open (optional)
    closeMenu();
  };

  return (
    <nav className=" left-0 right-0 z-20 bg-transparent py-4   px-8 md:px-10 lg:px-16 flex items-center justify-between">
      <div className="text-black text-lg font-semibold flex items-center">
        <img
          className="xl:w-fit lg:w-[100px] md:w-[100px] w-[60.23px] xl:h-[140px] lg:h-[65.14px] md:-h-[65.14px] h-[45.14px]"
          src={"../../../images/Logo.png"}
          alt=""
        />
      </div>
      <div className="hidden md:flex space-x-10">
        <Link
          to="/"
          onClick={() => handleClick("/")}
          className=" hover:text-black text-[#01b305] cursor-pointer no-underline transition-transform hover:scale-110 duration-300 ease-in"
        >
          Home
        </Link>
        <Link
          to="/about"
          onClick={() => handleClick("/about")}
          className="text-black cursor-pointer hover:text-[#01b305] no-underline transition-transform hover:scale-110 duration-300 ease-in"
        >
          About Us
        </Link>
        <Link
          to="/events"
          onClick={() => handleClick("/events")}
          className="text-black cursor-pointer hover:text-[#01b305] no-underline transition-transform hover:scale-110 duration-300 ease-in"
        >
          Events
        </Link>
        <Link
          to="/testimonials"
          onClick={() => handleClick("/testimonials")}
          className="text-black cursor-pointer hover:text-[#01b305] no-underline transition-transform hover:scale-110 duration-300 ease-in"
        >
          Testimonials
        </Link>
        <Link
          to="/gratitude"
          onClick={() => handleClick("/gratitude")}
          className="text-black cursor-pointer hover:text-[#01b305] no-underline transition-transform hover:scale-110 duration-300 ease-in"
        >
          Gratitude
        </Link>
        {/* <Link
          to="/tec"
          onClick={() => handleClick("/testimonials")}
          className="text-black cursor-pointer hover:text-[#01b305] no-underline transition-transform hover:scale-110 duration-300 ease-in"
        >
          TEC Program
        </Link> */}
      </div>
      {/* <div className="flex items-center gap-3">
        <div>
          <Link
            to="/login"
            onClick={() => handleClick("/login")}
            className="px-5 py-2 lg:block md:block hidden rounded-md hover:text-[#01b305] text-black no-underline transition-transform hover:scale-110 duration-300 ease-in"
          >
            Log in
          </Link>
        </div>
        <div>
          <Link
            to="/signup"
            onClick={() => handleClick("/signup")}
            className="px-5 py-2 lg:block md:block font-medium hidden rounded-md text-[#fff] bg-[#01b305] no-underline transition-transform hover:scale-110 duration-300 ease-in"
          >
            Create account
          </Link>
        </div>
      </div> */}

      <div className="md:hidden">
        <button
          onClick={() => setMenuOpen(!menuOpen)}
          className="text-black focus:outline-none "
        >
          <FaHamburger />
        </button>
      </div>
      {menuOpen && (
        <div className="md:hidden font-semibold absolute top-24 left-0 w-full bg-white p-4 space-y-8">
          <Link
            to="/"
            onClick={closeMenu}
            className=" block cursor-pointer no-underline hover:text-black text-[#01b305] "
          >
            Home
          </Link>
          <Link
            to="/about"
            onClick={() => handleClick("/about")}
            className="text-black block cursor-pointer no-underline hover:text-[#01b305]"
          >
            About Us
          </Link>

          <Link
            to="/events"
            // onClick={closeMenu}
            onClick={() => handleClick("/about")(closeMenu)}
            className="text-black block cursor-pointer no-underline hover:text-[#01b305]"
          >
            Events
          </Link>
          <Link
            to="/testimonials"
            onClick={closeMenu}
            className="text-black block cursor-pointer no-underline hover:text-[#01b305] "
          >
            Testimonials
          </Link>
          <Link
            to="/gratitude"
            onClick={closeMenu}
            className="text-black block cursor-pointer no-underline hover:text-[#01b305] "
          >
            Gratitude
          </Link>
          {/* <Link
            to="/tec"
            onClick={closeMenu}
            className="text-black block cursor-pointer no-underline hover:text-[#01b305] "
          >
            TEC Program
          </Link> */}
          {/* <Link
            to="/login"
            onClick={closeMenu}
            className="px-4 py-2 lg:hidden grid justify-center font-bold md:hidden cursor-pointer  rounded-3xl text-black  no-underline transition-transform hover:scale-110 duration-300 ease-in"
          >
            Login
          </Link>
          <Link
            to="/signup"
            onClick={closeMenu}
            className="px-4 py-2 lg:hidden grid justify-center font-bold md:hidden cursor-pointer  rounded-3xl text-[#fff] bg-[#01b305]  no-underline transition-transform hover:scale-110 duration-300 ease-in"
          >
            Create account
          </Link> */}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
